
export type PDFType = string;

export const ChartNote: PDFType = "chartNote",
	ResultPDF: PDFType = "result",
	OrderPDF: PDFType = "order"

export type PaymentMethod = number;

export const ClientBill: PaymentMethod = 0,
	SelfPay: PaymentMethod = 1,
	InsurancePay: PaymentMethod = 2,
	NoBill: PaymentMethod = 3

export type ProviderType = string;

export const ProviderTypeDoctor: ProviderType = "Doctor",
	ProviderTypeNurse: ProviderType = "Nurse",
	ProviderTypeOther: ProviderType = "Other"

export interface DataRow {
	ID: number,
}

export type Note = {
	ID: number,
	CreatedAt: string,
	Note: string,
	AddedBy: User,
}

export type Category = {
	ID: Number,
	Name: string,
	Code: string
};

export type Patient = {
	ID: number,
	FirstName: string,
	LastName: string
	DOB: string
	Facility?: Facility
	FacilityID: number
	PatientInsurances: PatientInsurance[],
};

export type Collector = {
	ID: number,
	FirstName: string,
	LastName: string
};

export type PatientInsurance = {
	ID: number
	InsuranceID: number,
}

export type Profile = {
	ID: number,
	Name: string,
	Tests: Test[],
}

export type Provider = {
	ID: number;
	FirstName: string;
	LastName: string;
	NPI: string;
	Type: string;
	SignatureOnFile: boolean;
	Facilities: number[];
	Contacts: number[];
}

export type Shipment = {
	Tracking: string
	Type: string
	Samples: number[],
	DateShipped: Date,
	Courier: string,
	StartMileage: number | null,
	StopMileage: number | null,
	DestinationID: number | null,
}

export type User = {
	ID: number,
	FirstName: string;
	LastName: string;
	Email: string;
}

export type Sample = {
	ID: number,
	OrderID: number,
	Order: Order;
	Status: string;
	Barcode: string;
	DateCollected: string;
	SpecimenTypeID: number;
	SpecimenType: SpecimenType;
}

export type SpecimenType = {
	ID: number,
	Container: string,
	Specimen: string,
	QTY: number,
}

// sort of specimen type and sample merged
export type NewSample = {
	ID: number,
	Barcode: string,
	QTY: number,
}

export type Result = {
	ID: number,
	OrderID: number,
	Critical: boolean,
	Abnormal: boolean,
	IsVisible: boolean,
	IsPanel: boolean,
	PanelID: number | null,
	SampleID: number,
	Sample: Sample,
	Hierarchy?: number[],
	ReplicateParentID: number | null,
}

export type ResultUpdateAction = string

export const ResultUpdateActionUpdate = "Update",
	ResultUpdateActionValidate = "Validate",
	ResultUpdateActionUnvalidate = "Unvalidate",
	ResultUpdateActionReject = "Reject",
	ResultUpdateActionClear = "Clear"

export const ResultUpdateAvailableActions = [
	ResultUpdateActionUpdate,
	ResultUpdateActionValidate,
	ResultUpdateActionUnvalidate,
	ResultUpdateActionReject,
	ResultUpdateActionClear
]

export type Laboratory = {
	ID: number,
	Reference: boolean,
	Name: string,
}

export type ResultUpdate = {
	Value?: string,
	ResultID: number,
	Action: ResultUpdateAction,
	BypassMethod?: boolean,
	Abnormal?: boolean,
	Critical?: boolean
}

export type RefRangeList = {
	Value: string
}

export type Facility = {
	ID: number,
	Name: string,
	Phone: string,
	Address: Address,
	CategoryFilter: Category[],
	CanInsurance: boolean,
	CanClientBill: boolean,
	CanSelfPay: boolean,
	CanNoBill: boolean,
	RequireIDCapture: boolean,
	RequireInsuranceCapture: boolean
	RequirePatientPhotoCapture: boolean
}

export type Address = {
	Address1: string,
	Address2: string,
	City: string,
	State: string,
	ZipCode: string
}

export type OrderCreate = {
	ReferenceID: string,
	Tests: number[],
	Profiles: number[],
	Reflexes: number[],
	ProviderID: number | null,
	Provider?: Provider | null,
	FacilityID: number | null,
	Facility?: Facility | null,
	PatientID: number | null,
	Patient?: Patient | null,
	DiagnosisCodes: string[],
	PaymentMethod: number | null,
	Stat: boolean,
	ProviderNotesRequired: boolean,
	RequestedCollectionDate: string | null,
}

export type Reflex = {
	ReflexType: string,
	DestTest: number,
	DestProfileID: number,
}

export type Order = {
	ID: number,
	Provider: Provider,
	Facility: Facility,
	Patient: Patient,
	Stat: boolean,
	Status: string,
}

export type TextEvent = {
	target: Target;
}

export type Target = {
	value: any;
}

export type DiagnosisQuestion = {
	ID: number,
	DiagnosisCodeID: string,
	DiagnosisCode: DiagnosisCode,
	Question: string,
	Enabled: boolean,
}

export type AOE = {
	ID: number,
	Question: string,
	Enabled: boolean,
}

export type DiagnosisCode = {
	Code: string;
	OrderNumber: number;
	Description: string;
}

export type Answer = {
	ID: number;
	Answer: string;
}

export type DiagnosisValidityRule = {
	ConvertedNCDRule: string;
	CPTCode: string;
	DiagnosisCode: DiagnosisCode | null;
	Supported: boolean;
}

export type EMRMessage = {
	ID: number;
	MessageID: string;
	MessageType: string;
	IntegrationID: number | null;
	AttachmentID: number | null;
	Status: string;
	Error: string;
}

export type Test = {
	ID: number;
	Code: number;
	Name: string;
	CPTCode: string;
}

export type IntegrationTestMapping = {
	ID?: number;
	TestCode: number | null;
	ExternalTestCode: string;
	IntegrationID: number;
	Test?: Test | null,
}

export type IntegrationInsuranceMapping = {
	ID?: number;
	InsuranceID: number | null;
	ExternalInsurance: string;
	IntegrationID: number;
	Insurance?: Insurance | null,
}

export type IntegrationFacilityMapping = {
	ID?: number;
	FacilityID: number | null;
	ExternalFacility: string;
	IntegrationID: number;
	Facility?: Facility | null,
}

export type FacilityReportServiceTestMapping = {
	ID?: number;
	FacilityID: number | null;
	Facility?: Facility | null,
	ReportServiceID: number | null;
	TestCode: number | null;
	Test?: Test | null,
	Enable: boolean;
}

export type FrequencyRule = {
	ID?: number;
	TestCode: number | null;
	Frequency: number;
	FrequencyWithUniqueDx: number;
	TimeSpan: number;
	OnAbnormalResult: boolean;
	PreventOrder: boolean;
}

export type Insurance = {
	ID?: number;
	Name: string;
}

export type OrderVerificationResult = {
	// gorm.Model
	// OrderID      uint
	// Verifier     VerificationType
	// Verified     bool
	// Action       VerificationAction
	// Error        string
	// Resolved     bool
	// ResolvedByID *uint
	// ResolvedBy   User
	ID: number;
	OrderID: number;
	UpdatedAt: string;
	Verifier: number;
	Verified: boolean;
	Action: number;
	Error: string;
	Resolved: boolean;
	ResolvedByID: number | null;
	ResolvedByUser: User;
}
