import { useState, useEffect } from "react";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import ResultsTable from "../components/ResultsTable";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, { a11yProps } from "../components/TabPanel";
import ExtractionPlatesTable from "./ExtractionPlateTable";
import ExtractionPlateSetup from "./ExtractionPlateSetup";
import PCRPlateSetup from "./PCRPlateSetup";
import { addInterfaceResultFile, getInstrumentInterfaces } from "../services/samples";
import { Alert, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { TextField, Input, MenuItem, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
// import MenuIcon from "@mui/icons-material/Menu";
import { Stack } from "@mui/system";
import { Form, Formik } from "formik";
import InstrumentResultFilesTable from "./InstrumentResultFiles";
import LoadingButton from "@mui/lab/LoadingButton";
import PlateMapFilesTable from "./PlateMapFiles";
import { getInstruments } from "../services/methods";

export default function Molecular() {
    const [value, setValue] = useState(0);
    const [showUpload, setShowUpload] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [interfaces, setInterfaces] = useState([]);
    const [instruments, setInstruments] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        getInstruments()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load interfaces");
                }
                return p.json();
            })
            .then((p) => {
                setInstruments(p);
            })
            .catch(e => setError(e.message));

        getInstrumentInterfaces()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load interfaces");
                }
                return p.json();
            })
            .then((p) => {
                setInterfaces(p);
            })
            .catch(e => setError(e.message));
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const validationSchema = Yup.object().shape({
        File: Yup.mixed(),
        Interface: Yup.number(),
    })

    const initialValues = {
        File: "",
        Interface: "",
        InstrumentIdentifier: "",
    }

    const handleSubmit = (values) => {
        setProcessing(true)
        console.log(values);
        addInterfaceResultFile(values.Interface, values.File, { "InstrumentIdentifier": values?.InstrumentIdentifier })
            .then(p => {
                if (!p.ok) {
                    throw new Error("Failed to save results file")
                }
                setError(null)
                setShowUpload(false)
                return p.json()
            }).catch(e => setError(e.message))
            .finally(() => setProcessing(false))
    };

    const getInstrumentsByInterface = (interfaceID) => {
        let instruments = [];
        for (let int of interfaces) {
            if (int.ID === interfaceID) {
                for (let instr of int.Instruments) {
                    instruments.push(instr)
                }
            }
        }

        return instruments;
    }

    return (
        <>
            <Dialog open={showUpload}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        errors,
                        setFieldValue,
                    }) => (
                        <Form>
                            <DialogContent>
                                <Stack direction="column" spacing={2}>
                                    <Typography variant="body1">Select molecular result file and associated interface.</Typography>
                                    <Stack direction="row" spacing={2}>
                                        <Input
                                            required
                                            type="file"
                                            inputProps={{ accept: ".csv" }}
                                            onChange={(e) => {
                                                console.log(e);
                                                setFieldValue("File", e.target.files[0]);
                                            }}
                                        />

                                        <TextField
                                            required
                                            select
                                            size="small"
                                            value={values?.Interface}
                                            name="Interface"
                                            label="Interface"
                                            sx={{ minWidth: "200px" }}
                                            errors={errors?.Interface}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            {interfaces.map((value, index) => (
                                                <MenuItem key={index} value={value.ID}>
                                                    {value.Name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        {[3, 11].includes(values?.Interface) && <TextField
                                            required
                                            select
                                            size="small"
                                            value={values?.InstrumentIdentifier}
                                            name="InstrumentIdentifier"
                                            label="Instrument"
                                            sx={{ minWidth: "200px" }}
                                            errors={errors?.InstrumentIdentifier}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            {instruments.map((value, index) => (
                                                <MenuItem key={index} value={value.InstrumentIdentifier}>
                                                    {value.Name}
                                                </MenuItem>
                                            ))}
                                        </TextField>}
                                    </Stack>
                                </Stack>
                            </DialogContent>
                            {error !== null ? <Alert sx={{ mx: 2 }} severity="error">{error}</Alert> : null}
                            <DialogActions>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={processing}
                                    onClick={() => {
                                        setShowUpload(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <LoadingButton
                                    startIcon={<SaveIcon />}
                                    color="success"
                                    variant="contained"
                                    loading={processing}
                                    disabled={processing}
                                    type="submit"
                                >
                                    <span>Save</span>
                                </LoadingButton>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Stack direction="row" justifyContent="space-between">
                    <Tabs value={value} onChange={handleChange}
                        variant="scrollable"
                    >
                        <Tab label="Extraction Plates" {...a11yProps(0)} />
                        <Tab label="PCR Plates" {...a11yProps(1)} />
                        <Tab label="Plate Maps" {...a11yProps(2)} />
                        <Tab label="Result Files" {...a11yProps(3)} />
                    </Tabs>
                    <Button
                        onClick={() => setShowUpload(true)}
                        variant="contained"
                        color="primary"
                        sx={{ maxHeight: "35px", minWidth: "145px" }}
                        size="small"
                    >
                        Upload Results
                    </Button>
                </Stack>
            </Box>
            <TabPanel value={value} index={0}>
                <ExtractionPlateSetup />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <PCRPlateSetup />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <PlateMapFilesTable />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <InstrumentResultFilesTable />
            </TabPanel>
        </>
    );
}
